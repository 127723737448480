<!--
// nuxt-ui/components/Shared/HeaderBannerTitle.vue
-->
<script setup lang="ts">
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";

type Props = {
  title: string;
};

defineProps<Props>();

const { isQueens, isKings } = useWebContext();
</script>

<template>
  <div
    :class="[
      'flex items-center justify-between align-center px-4 md:pl-20 py-6 md:py-20 bg-blend-difference',
      isKings ? 'kql-banner-gradient-kings' : 'kql-banner-gradient-queens',
    ]"
  >
    <h2
      :class="[
        'text-transparent uppercase text-3xl md:text-6xl kql-banner-font-family bg-gradient-to-r inline-block bg-clip-text',
        isKings ? 'from-secondary to-primary' : 'from-primary to-secondary',
      ]"
    >
      {{ title }}
    </h2>
  </div>
</template>

<style scoped>
.kql-banner-gradient-kings {
  @apply bg-none md:bg-opacity-30 md:bg-[url("~/nuxt-ui/assets/images/header-title-texture.png"),url("~/nuxt-ui/assets/images/header-title-decoration.png")];
  background-repeat: repeat-x, no-repeat;
  background-position:
    center left,
    center right;
  background-size:
    auto 130%,
    auto 100%;
}

.kql-banner-gradient-queens {
  @apply bg-none md:bg-opacity-30 md:bg-[url("~/nuxt-ui/assets/images/header-title-texture.png"),url("~/nuxt-ui/assets/images/header-title-decoration-queens.png")];
  background-repeat: repeat-x, no-repeat;
  background-position:
    center left,
    center right;
  background-size:
    auto 130%,
    auto 100%;
}

.kql-banner-font-family {
  @apply font-black font-['Archivo_Expanded'];
}
</style>
